<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#FDEAE8" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.8362 14.0583C26.2343 14.2004 26.5 14.5774 26.5 15.0001V21.0001H32.5C32.8864 21.0001 33.2382 21.2227 33.4037 21.5718C33.5691 21.921 33.5186 22.3342 33.274 22.6333L24.274 33.6333C24.0063 33.9604 23.5619 34.0839 23.1638 33.9418C22.7658 33.7998 22.5 33.4227 22.5 33.0001V27.0001H16.5C16.1136 27.0001 15.7618 26.7775 15.5963 26.4283C15.4309 26.0791 15.4814 25.6659 15.7261 25.3668L24.7261 14.3668C24.9937 14.0397 25.4381 13.9162 25.8362 14.0583Z"
      fill="#B82025"
    />
  </svg>
</template>
